/* maven-pro-regular - latin */
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/maven-pro-v32-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/maven-pro-v32-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/maven-pro-v32-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/maven-pro-v32-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/maven-pro-v32-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/maven-pro-v32-latin-regular.svg#MavenPro') format('svg'); /* Legacy iOS */
}
/* maven-pro-700 - latin */
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/maven-pro-v32-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/maven-pro-v32-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/maven-pro-v32-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/maven-pro-v32-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/maven-pro-v32-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/maven-pro-v32-latin-700.svg#MavenPro') format('svg'); /* Legacy iOS */
}
/* maven-pro-900 - latin */
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/maven-pro-v32-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/maven-pro-v32-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/maven-pro-v32-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/maven-pro-v32-latin-900.woff') format('woff'), /* Modern Browsers */
  url('../fonts/maven-pro-v32-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/maven-pro-v32-latin-900.svg#MavenPro') format('svg'); /* Legacy iOS */
}

@import "assets/website/styles/helper/_variables.scss";

body {
  background-color: $sc-white-01;
  font-family: 'Maven Pro', sans-serif;
  margin: 0;
  font-size: 1.4em;
}

#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 600px; /* Footer height + padding + little bit space */
}

a:link, a:visited {
  text-decoration: none;
  color: $sc-black-01;
}

a:hover, a:active {
  text-decoration: none;
  color: $sc-black-02;
}

li {
  list-style-type: none;
}

.textBlue {
  color: $sc-blue-01;
}

#main {
  margin: -100px 30px 0 30px;
  line-height: 1.5;
}

#headline {
  position: relative;
  z-index: 5;
  top: -275px;
  font-size: 2.0em;
  font-weight: 900;
  text-transform: uppercase;
  color: $sc-white-01;
}

.contentDIV {
  display: flex;
}

.contentMain {
  width: 70%;
}

.contentInfo {
  z-index: 5;
  width: 30%;
  margin-top: -100px;
  margin-left: 2%;
}

.contentTilesFrame {
  display: flex;
}

.contentTiles {
  width: 50%;
}

.contentTilesFullWidth {
  width: 100%;
}

#footer {
  position: absolute;
  display: flex;
  bottom: 0;
  width: 100%;
  height: 300px;
  padding: 110px 0 50px 0;
  background-color: $sc-blue-02;
  color: $sc-black-01;
  font-size: 0.8em;
  text-align: right;
  line-height: 1.5;
}

.footer_01 {
  width: 30%;
}

.footer_02 {
  width: 30%;
}

.footer_03 {
  width: 30%;
}

.footerNavi, .footerNaviMember {
  display: block;
}

.footerNaviMemberHeadline {
  color: $sc-red-01;
  font-weight: 700;
}

a.footerNaviMember:link, a.footerNaviMember:visited {
  text-decoration: none;
  color: $sc-red-01;
}

a.footerNaviMember:hover, a.footerNaviMember:active {
  text-decoration: none;
  color: $sc-red-05;
}

.footerHome {
  width: 17px;
  height: auto;
  margin-bottom: -2px;
}

.footerIconHome {
  fill: $sc-black-01;
}

.footerCopyright {
  font-size: 0.8em;
  margin-top: 30px;
}

/* VIDEO ######################################################## */

video {
  width: 100%;
}

.videoFrame {
  position: relative;
  z-index: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-color: $sc-white-01;
  overflow: hidden;
}

video.videoBackground {
  width: 100%;
  visibility: visible;
}

.videoBackgroundNovideo {
  width: 100%;
  visibility: hidden;
}

.bow_01 {
  position: absolute;
  z-index: 2;
  left: 0;
  width: 100%;
  height: auto;
  bottom: -5px;
  margin: 0;
  overflow: hidden;
}

.bow_02 {
  position: absolute;
  z-index: 3;
  left: 0;
  width: 100%;
  height: auto;
  bottom: -5px;
  margin: 0;
  overflow: hidden;
}

.logocircleFrame {
  position: absolute;
  z-index: 4;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  width: 400px;
  height: 400px;
  top: 50%;
  left: 50%;
  margin-top: -200px;
  margin-left: -200px;
}

.logocircle {
  position: absolute;
  z-index: 5;
  background-color: rgba(255, 255, 255, 1);
  background-image: url("../images/logo_500.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 95%;
  border-radius: 50%;
  width: 90%;
  height: 90%;
  top: 5%;
  left: 5%;
}

.naviTopLeft {
  position: fixed;
  z-index: 3;
  top: 30px;
  left: 30px;
  width: 60px;
  height: auto;
}

.naviTopIconMenu, .naviTopIconFacebook, .naviTopIconInstagram {
  fill: $sc-white-01;
  cursor: pointer;
}

.naviTopRight {
  position: fixed;
  z-index: 100;
  top: 30px;
  right: 30px;
}

.naviTopFacebook {
  margin-top: 1px;
  margin-right: 7px;
}

.naviTopInstagram {
  margin-top: 1px;
  margin-right: 7px;
}

.naviTopSocialSVG {
  width: 35px;
}

button {
  display: inline-block;
  padding: 8px 26px;
  background: $sc-black-01;
  border: none;
  border-radius: 100px;
  font-family: 'Maven Pro', sans-serif;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 1px;
  color: $sc-white-01;
  cursor: pointer;
}

button.naviTopLanguage {
  vertical-align: top;
  background: $sc-white-01;
  color: $sc-blue-01;
}

button.naviTopLogin {
  vertical-align: top;
  background: $sc-black-01;
  color: $sc-blue-01;
}

button.naviTopLogout {
  vertical-align: top;
  background: $sc-red-01;
  color: $sc-blue-01;
}

button.preview {
  padding: 4px 13px;
  border-radius: 100px;
  font-size: 0.8em;
}

.sitemap {
  position: fixed;
  z-index: 6;
  top: 0;
  right: -5000px;
  padding-top: 7%;
  width: 100%;
  height: 100%;
  background-color: $sc-blue-01;
}

.sitemapNavi {
  width: 100%;
  display: flex;
  height: 6%;
  margin-bottom: 1px;
  font-size: 0.8em;
  align-items: center;
  padding-left: 20%;
  background-color: $sc-black-01;
  color: $sc-white-01;
}

a.sitemapNavi:link, a.sitemapNavi:visited {
  text-decoration: none;
  color: $sc-white-01;
}

a.sitemapNavi:hover, a.sitemapNavi:active {
  text-decoration: none;
  color: $sc-white-01;
}

.sitemapHome {
  width: 22px;
  height: auto;
  margin-right: 10px;
  margin-left: -33px;
}

.sitemapIconHome {
  fill: $sc-white-01;
}

/* CONTENT INFO ######################################################## */

.swimTrail {
  position: relative;
  margin: 2em 0 2em 0;
}

.swimTrailIMG {
  width: 85%;
  height: auto;
}

.swimTrailLink {
  position: absolute;
  bottom: 20%;
  right: 10px;
}

.eventPreview {
  display: block;
  margin-top: 5px;
  padding: 10px;
  background-color: $sc-blue-04;
  border-radius: 3px 15px 5px 30px;
  font-size: 0.8em;
}

.newsPreview {
  display: block;
  margin-top: 5px;
  padding: 10px;
  background-color: $sc-blue-05;
  border-radius: 3px 15px 5px 30px;
  font-size: 0.8em;
}

.eventPreviewDate {
  display: block;
  font-weight: 700;
}

.eventPreviewText {
  display: block;
  padding: 3px 0 3px 0;
}

.eventPreviewLink {
  display: block;
  text-align: right;
}

.eventPreviewIcon {
  width: 30px;
  margin-bottom: -8px;
}

.infoFrame {
  padding: 20px;
  background-color: rgba(239, 239, 239, 0.6);
  border-radius: 3px 15px 5px 30px;
}

.infoHeader {
  display: flex;
}

.infoHeader.left, .infoHeader.right {
  display: block;
  width: 50%;
  padding: 3px;
}

.infoHeaderHeadline {
  width: 50px;
  font-weight: 900;
}

.infoHeaderText {
}

.contentPicture {
  float: left;
  width: 45%;
  margin-right: 1.8em;
  margin-bottom: 1em;
  margin-top: 0.3em;
  border-radius: 3px 15px 5px 30px;;
}

.downloadIcon {
  width: 20px;
  margin-bottom: -2px;
}

.previewTile {
  display: block;
  margin: 20px 20px 0 0;
  padding: 10px;
  background-color: $sc-grey-01;
  border-radius: 3px 15px 5px 30px;
}

.previewTileIcon {
  width: 30px;
  margin-bottom: -8px;
}

.previewTileImage {
  width: 100%;
  margin-top: 5px;
}

.previewTileText {
  display: block;
  padding: 3px 0 3px 0;
}

.previewTileLink {
  display: block;
  text-align: right;
}

.previewGalleryImage {
  width: 22%;
  margin: 1%;
  border-radius: 3px 15px 5px 30px;
}

/* FORMS ######################################################## */

//honney pod
.formMembercode {
  display: none;
}


.formFrame {
  display: block;
  margin: 20px 20px 0 0;
  padding: 40px 20px 20px 20px;
  background-color: $sc-grey-01;
  border-radius: 3px 15px 5px 30px;
}

.formFrame input {
  width: 100%;
  font-size: 0.8em;
  border-radius: 5px;
  line-height: 15px;
  background-color: $sc-white-01;
  border: 1px solid $sc-grey-02;
  transition: all 0.3s;
  padding: 8px;
  margin-bottom: 15px;
  box-sizing: border-box;
  outline: 0;
}

.formFrame textarea {
  width: 100%;
  font-size: 0.8em;
  border-radius: 5px;
  line-height: 15px;
  background-color: $sc-white-01;
  border: 1px solid $sc-grey-02;
  transition: all 0.3s;
  padding: 8px;
  margin-bottom: 15px;
  box-sizing: border-box;
  outline: 0;
}

.formFrame select {
  width: 100%;
  font-size: 0.8em;
  border-radius: 5px;
  line-height: 15px;
  background-color: $sc-white-01;
  border: 1px solid $sc-grey-02;
  transition: all 0.3s;
  padding: 8px;
  margin-bottom: 15px;
  box-sizing: border-box;
  outline: 0;
  -webkit-appearance: none;
}
.formFrame select option {
  background-color: $sc-white-01;
}

.formButton {
  margin-top: 20px;
  text-align: right;
}

.formFooter {
  font-size: 0.8em;
}

/* Mobile Small */

@media only screen and (max-width: 450px) {
  body {
    font-size: 0.6em;
  }

  .logocircleFrame {
    width: 100px;
    height: 100px;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
  }
  .naviTopLeft {
    top: 10px;
    left: 10px;
    width: 35px;
  }

  .naviTopRight {
    top: 10px;
    right: 10px;
  }

  .naviTopSocial {
    margin-right: 1px;
  }

  .naviTopSocialSVG {
    width: 18px;
  }

  button {
    padding: 4px 13px;
    font-size: 8px;
  }

  button.swimTrail {
    padding: 8px 26px;
    font-size: 15px;
    letter-spacing: 1px;
  }

  #main {
    margin: -30px 10px 0 10px;
    line-height: 1.5;
  }

  #headline {
    top: -30px;
    font-size: 1.0em;
  }

  .contentDIV {
    display: block;
  }

  .contentDIV {
    display: block;
  }

  .contentMain {
    width: 100%;
  }

  .contentInfo {
    z-index: 5;
    width: 100%;
    margin-top: 0;
    margin-left: 2%;
  }

  .previewTileIcon {
    width: 20px;
    margin-bottom: -6px;
  }

  .footerHome {
    width: 10px;
  }

  .sitemap {
    padding-top: 12%;
  }

  .sitemapNavi {
    height: 5%;
  }
}

/* Mobile Big */

@media only screen and (min-width: 451px) and (max-width: 767px) {
  body {
    font-size: 0.8em;
  }

  .logocircleFrame {
    width: 160px;
    height: 160px;
    top: 50%;
    left: 50%;
    margin-top: -80px;
    margin-left: -80px;
  }

  .naviTopLeft {
    top: 10px;
    left: 10px;
    width: 35px;
  }

  .naviTopRight {
    top: 10px;
    right: 10px;
  }

  .naviTopSocial {
    margin-right: 1px;
  }

  .naviTopSocialSVG {
    width: 18px;
  }

  .button {
    padding: 4px 13px;
    font-size: 8px;
  }

  .button.swimTrail {
    padding: 8px 26px;
    font-size: 15px;
    letter-spacing: 1px;
  }

  #main {
    margin: -40px 10px 0 10px;
    line-height: 1.5;
  }

  .contentDIV {
    display: block;
  }

  .contentMain {
    width: 100%;
  }

  .contentInfo {
    //z-index: 5;
    width: 100%;
    margin-top: 0;
    margin-left: 2%;
  }

  #headline {
    top: -50px;
    font-size: 1em;
  }

  .footerHome {
    width: 10px;
  }

  .sitemap {
    padding-top: 7%;
  }

}

/* Tablet */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  body {
    font-size: 1em;
  }

  .logocircleFrame {
    width: 230px;
    height: 230px;
    top: 50%;
    left: 50%;
    margin-top: -115px;
    margin-left: -115px;
  }

  .button {
    padding: 4px 13px;
    font-size: 12px;
  }

  #main {
    margin: -40px 30px 0 30px;
    line-height: 1.5;
  }

  #headline {
    top: -100px;
    font-size: 1.6em;
  }

  .sitemap {
    padding-top: 10%;
  }

}

/* Desktop */

@media only screen and (min-width: 992px) and (max-width: 1366px) {
  body {
    font-size: 1.2em;
  }

  .logocircleFrame {
    width: 340px;
    height: 340px;
    top: 50%;
    left: 50%;
    margin-top: -170px;
    margin-left: -170px;
  }

  #main {
    margin: -65px 30px 0 30px;
    line-height: 1.5;
  }

  #headline {
    top: -135px;
    font-size: 1.6em;
  }

  .sitemap {
    padding-top: 7%;
  }

}
