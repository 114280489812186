//colors
$sc-white-01: #fff;

$sc-black-01: #262626;
$sc-black-02: #000000;

$sc-grey-01: #efefef;
$sc-grey-02: #b3b2b2;
$sc-grey-03: #575656;

$sc-blue-01: #00a5e6;
$sc-blue-02: #3d83a6;
$sc-blue-03: #92c2d8;
$sc-blue-04: #9cddf1;
$sc-blue-05: #60c8e9;

$sc-red-01: #a81a1a;
$sc-red-02: #f5dfdf;
$sc-red-03: #e38989;
$sc-red-04: #e75c5c;
$sc-red-05: #911616;
